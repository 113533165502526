<template>
    <div ref="feedbackContainer" class="flex justify-center items-center">
        <ToastNotification message="Your feedback was sent successfully, Thank you!" :is-visible="feedbackSent" :bg-color="`#2196f3`" />
        <button
            @click="toggleFeedback"
            v-show="!showForm"
            class="feedback fixed bottom-5 right-2 w-14 h-14 flex justify-center items-center rounded-full text-white text-3xl bg-NeonBlue hover:bg-white hover:text-NeonBlue hover:shadow-[0_0_2px_1px_rgba(0,0,0,.25)] text"
        >
            <!-- <font-awesome-icon :icon="['far', 'comment-dots']" class="text" /> -->
            <div class="text">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 27 27" fill="none">
                    <path
                        d="M17.0269 14.0625C16.8919 14.0625 16.7513 14.04 16.6163 13.995C16.3627 13.9143 16.1417 13.7543 15.9858 13.5386C15.8298 13.323 15.7472 13.063 15.75 12.7969V11.2163C14.4788 11.0138 13.5 9.90562 13.5 8.57812V4.92188C13.5 3.44813 14.6981 2.25 16.1719 2.25H22.6406C24.1144 2.25 25.3125 3.44813 25.3125 4.92188V8.57812C25.3125 10.0519 24.1144 11.25 22.6406 11.25H19.7606L18.0281 13.5563C17.7806 13.8825 17.415 14.0625 17.0269 14.0625ZM16.1719 3.65625C15.4744 3.65625 14.9062 4.22438 14.9062 4.92188V8.57812C14.9062 9.27563 15.4744 9.84375 16.1719 9.84375H17.1562V12.375L19.0575 9.84375H22.6462C23.3438 9.84375 23.9119 9.27563 23.9119 8.57812V4.92188C23.9119 4.22438 23.3438 3.65625 22.6462 3.65625H16.1719ZM9 15.4688C6.67125 15.4688 4.78125 13.5787 4.78125 11.25C4.78125 8.92125 6.67125 7.03125 9 7.03125C11.3287 7.03125 13.2188 8.92125 13.2188 11.25C13.2188 13.5787 11.3287 15.4688 9 15.4688ZM9 8.4375C7.4475 8.4375 6.1875 9.6975 6.1875 11.25C6.1875 12.8025 7.4475 14.0625 9 14.0625C10.5525 14.0625 11.8125 12.8025 11.8125 11.25C11.8125 9.6975 10.5525 8.4375 9 8.4375ZM1.6875 19.5694C1.6875 19.62 1.77187 24.75 9 24.75C16.2281 24.75 16.3125 19.62 16.3125 19.5694V18.9844C16.3125 17.82 15.3675 16.875 14.2031 16.875H3.79688C2.6325 16.875 1.6875 17.82 1.6875 18.9844V19.5694ZM3.09375 18.9844C3.09375 18.5963 3.40875 18.2812 3.79688 18.2812H14.2031C14.5913 18.2812 14.9062 18.5963 14.9062 18.9844V19.5637C14.9062 19.7156 14.7825 23.3438 9 23.3438C3.2175 23.3438 3.09375 19.7156 3.09375 19.5694V18.9844Z"
                        fill="#fff"
                    />
                </svg>
            </div>
        </button>
        <div v-if="showForm" class="formContainer z-10 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[rgba(0,0,0,.4)]">
            <div
                ref="FeedbackForm"
                class="bg-white rounded-lg flex flex-col items-center justify-between overflow-y-scroll w-11/12"
                :class="category === 'category' ? 'lg:w-1/3 h-3/5' : 'lg:w-2/5 h-4/5'"
            >
                <div v-show="category !== 'category'" class="flex flex-row justify-between p-8 bg-NeonBlue text-white w-full">
                    <button @click="category = 'category'"><font-awesome-icon :icon="['fas', 'angle-left']" /></button>
                    <span class="text-xl">{{ category }}</span>
                    <button @click="toggleFeedback"><font-awesome-icon :icon="['fas', 'x']" /></button>
                </div>
                <Transition name="slide">
                    <div v-show="category === 'category'" class="w-full h-full flex flex-col items-center justify-center gap-8 p-0">
                        <div class="w-full flex justify-end p-0 pr-5" style="margin-top: -12%">
                            <button class="nav-btn trash-btn" @click="toggleFeedback">
                                <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                                <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                            </button>
                        </div>
                        <button @click="category = 'Report a bug'" class="flex justify-start gap-5 w-3/5 items-center text-gray-400 p-2 hover:border-gray-200 border border-transparent rounded-md">
                            <span class="w-14 h-14 p-1 flex justify-center items-center rounded-full border border-gray-200 text-xl">
                                <!-- <font-awesome-icon :icon="['fas', 'bug']" /> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M11.5 15.5C10.7044 15.5 9.94129 15.1839 9.37868 14.6213C8.81607 14.0587 8.5 13.2956 8.5 12.5V10.5M11.5 15.5C12.2956 15.5 13.0587 15.1839 13.6213 14.6213C14.1839 14.0587 14.5 13.2956 14.5 12.5V10.5M11.5 15.5V10.5M8.5 10.5C8.5 9.70435 8.81607 8.94129 9.37868 8.37868C9.94129 7.81607 10.7044 7.5 11.5 7.5C12.2956 7.5 13.0587 7.81607 13.6213 8.37868C14.1839 8.94129 14.5 9.70435 14.5 10.5M8.5 10.5H14.5M14.572 11.5H16M7 11.5H8.5M14.5 14L15.5 14.5M8.5 14L7.5 14.5M14.5 9L15.5 8.5M8.5 9L7.5 8.5M18.5 18.5L22 22"
                                        stroke="#C3CAD9"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M6.75177 3.26982C8.74786 2.11794 11.0946 1.73094 13.3549 2.1809C15.6151 2.63087 17.6347 3.88712 19.0375 5.71564C20.4403 7.54415 21.1305 9.82024 20.9796 12.1199C20.8288 14.4196 19.8471 16.586 18.2175 18.2156C16.5879 19.8452 14.4215 20.8268 12.1219 20.9777C9.82219 21.1285 7.5461 20.4383 5.71759 19.0355C3.88907 17.6328 2.63282 15.6132 2.18286 13.3529C1.73289 11.0927 2.11989 8.74591 3.27177 6.74982"
                                        stroke="#C3CAD9"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </span>
                            <span class="flex flex-col justify-center items-center text-left">
                                <h1 class="w-full font-semibold text-xl text-neutral-700">Report a bug</h1>
                                <h2 class="w-full font-medium text-base">Let us know what's broken</h2>
                            </span>
                        </button>
                        <button @click="category = 'Feature request'" class="flex justify-start gap-5 w-3/5 items-center text-gray-400 p-2 hover:border-gray-200 border border-transparent rounded-md">
                            <span class="w-14 h-14 p-1 flex justify-center items-center rounded-full border border-gray-200 text-xl">
                                <!-- <font-awesome-icon :icon="['fas', 'bullhorn']" /> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M9 4L11.5 9.5L17 12L11.5 14.5L9 20L6.5 14.5L1 12L6.5 9.5L9 4ZM9 8.83L8 11L5.83 12L8 13L9 15.17L10 13L12.17 12L10 11L9 8.83ZM19 9L17.74 6.26L15 5L17.74 3.75L19 1L20.25 3.75L23 5L20.25 6.26L19 9ZM19 23L17.74 20.26L15 19L17.74 17.75L19 15L20.25 17.75L23 19L20.25 20.26L19 23Z"
                                        fill="#C3CAD9"
                                    />
                                </svg>
                            </span>
                            <span class="flex flex-col justify-center items-center text-left">
                                <h1 class="w-full font-semibold text-xl text-neutral-700">Feature request</h1>
                                <h2 class="w-full font-medium text-base">Tell us how we can improve</h2>
                            </span>
                        </button>
                        <button @click="category = 'General feedback'" class="flex justify-start gap-5 w-3/5 items-center text-gray-400 p-2 hover:border-gray-200 border border-transparent rounded-md">
                            <span class="w-14 h-14 p-1 flex justify-center items-center rounded-full border border-gray-200 text-xl">
                                <font-awesome-icon :icon="['fas', 'file-lines']" />
                            </span>
                            <span class="flex flex-col justify-center items-center text-left">
                                <h1 class="w-full font-semibold text-xl text-neutral-700">General feedback</h1>
                                <h2 class="w-full font-medium text-base">Give general feedback of this page</h2>
                            </span>
                        </button>
                    </div>
                </Transition>
                <Transition name="slide">
                    <form @submit.stop="submitFeedBack" v-show="category !== 'category'" action="" class="w-4/5 h-full flex flex-col items-center justify-center gap-5 px-8 py-3 pb-5">
                        <h1 class="font-medium text-2xl mt-8 mb-auto">
                            {{ FormHeader }}
                        </h1>
                        <input type="email" required class="w-full h-12 mx-auto border-2 border-gray-200 rounded-lg px-2 py-2 outline-none" placeholder="Your email adress" v-model="FeedbackEmail" />
                        <input type="text" required class="w-full h-12 mx-auto border-2 border-gray-200 rounded-lg px-2 py-2 outline-none" placeholder="Add a title" v-model="FeedbackTitle" />
                        <textarea
                            required
                            name="bugDescription"
                            id="bugDescription"
                            rows="6"
                            placeholder="Describe the bug in detail"
                            v-model="FeedbackDescription"
                            class="w-full h-32 mx-auto border-2 border-gray-200 rounded-lg resize-none py-2 px-2 outline-none"
                        ></textarea>
                        <div
                            class="flex items-center w-full h-12 mx-auto border-2 text-gray-400 border-gray-200 rounded-lg px-0 py-0 outline-none relative after:absolute after:w-px after:h-full after:content-[''] after:bg-gray-200 after:left-1/2"
                        >
                            <input type="file" name="feedbackVideo" id="feedBackVideo" class="w-1/2 flex justify-center items-center video" />
                            <input type="file" name="feedbackImg" id="feedBackImg" class="w-1/2 flex justify-center items-center" title="take a screenshot" />
                        </div>
                        <input type="submit" value="Submit" class="w-32 h-12 ml-auto bg-NeonBlue text-white rounded-full" />
                    </form>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ToastNotification from "./ToastNotification.vue";
import { useStore } from "../store/index";
export default {
    name: "FeedBack",
    data() {
        return {
            showForm: false,
            category: "category",
            FeedbackEmail: "",
            FeedbackTitle: "",
            FeedbackDescription: "",
            feedbackSent: false,
        };
    },
    components: {
        ToastNotification,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {
        // FeedbackEmail(){
        //     return this.Store.recruiterEmail
        // },
        FormHeader() {
            switch (this.category) {
                case "Report a bug":
                    return "Tell us what's broken";
                case "Feature request":
                    return "Suggest an improuvement";
                case "General feedback":
                    return "What do you think of us?";
            }
            return null;
        },
    },
    methods: {
        toggleFeedback() {
            this.FeedbackEmail = this.Store.recruiterEmail;
            (this.showForm = !this.showForm), (this.category = "category");
            if (this.showForm) {
                setTimeout(() => document.addEventListener("click", this.outsideClick), 1000);
            } else {
                document.removeEventListener("click", this.outsideClick);
                this.FeedbackEmail = "";
                this.FeedbackTitle = "";
                this.FeedbackDescription = "";
            }
        },
        outsideClick(event) {
            if (!this.$refs?.FeedbackForm?.contains(event.target)) {
                this.showForm = false;
                document.removeEventListener("click", this.outsideClick);
            }
        },
        submitFeedBack(event) {
            event.preventDefault();
            const feedback = JSON.stringify({
                category: this.category,
                email: this.FeedbackEmail,
                title: this.FeedbackTitle,
                text: this.FeedbackDescription,
            });
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/company/companyFeedback",
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
                data: feedback,
            };

            axios
                .request(config)
                .then((response) => {
                    console.log("deefback response....", JSON.stringify(response.data));
                })
                .catch((err) => {
                    console.error(err);
                });

            this.toggleFeedback();

            this.feedbackSent = true;
            setTimeout(() => (this.feedbackSent = false), 6000);
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
    transition: all 1s ease;
    transition-delay: 0.2s;
}

.slide-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    // transform: translateX(-500px);
    opacity: 0;
}

.slide-enter-to,
.slife-leave-from {
    opacity: 1;
    // transform: translateX(0);
}

.text {
    &:hover {
        svg {
            path {
                fill: #2196f3;
            }
        }
    }
}

.trash-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-icon {
        color: red;
    }
}

.trash-btn:hover {
    border: 2px solid red;
}

.feedback {
    background: rgb(123, 42, 255);
    background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%);
}

input[type="file"] {
    position: relative;
    outline: none;
    color: transparent;

    &::file-selector-button {
        /* File Selector Button Styles */
        border-radius: 4px;
        cursor: pointer;
        background-color: white;
        border: none;
        box-shadow: 0px 1px 0px rgba(#000, 0.05);
        width: 100%;
        height: 100%;

        color: transparent;

        /*This is a hack to change the button label. I'm hiding the default label and then manually applying the width based on updated icon and label.*/
        @supports (-moz-appearance: none) {
            /*Firefox doesn't support the pseudo ::before or ::after elements on this input field so we need to use the @supports rule to enable default styles fallback for Firefox.*/
            color: var(--primary-color);
        }
    }

    &::before {
        /* Faked label styles and icon */
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        height: 100%;
        width: 30px;
        content: "";
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d0d0d0"><g ><path d="M19.5 4h-15A2.5 2.5 0 0 0 2 6.5v11A2.5 2.5 0 0 0 4.5 20h15a2.5 2.5 0 0 0 2.5-2.5v-11A2.5 2.5 0 0 0 19.5 4Zm-15 2h15c.28 0 .5.22.5.5v8.09l-4.29-4.29a1 1 0 0 0-1.41 0l-4.29 4.29-2.29-2.29a1 1 0 0 0-1.41 0l-2.29 2.29V6.5c0-.28.22-.5.5-.5Zm15 12h-15a.5.5 0 0 1-.5-.5v-.09l3-3 2.29 2.29a1 1 0 0 0 1.41 0l4.29-4.29 5 5v.09a.5.5 0 0 1-.5.5Z"/><circle cx="9.5" cy="9.5" r="2"/></g></svg>');
        background-size: cover;
    }
}

input[type="file"].video {
    &::before {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d0d0d0"><path d="M21.54 6c-.35 0-.68.14-.93.38L17 10V7.5a2.5 2.5 0 0 0-2.5-2.5H3.5A2.5 2.5 0 0 0 1 7.5v9A2.5 2.5 0 0 0 3.5 19h11a2.5 2.5 0 0 0 2.5-2.5V14l3.62 3.62c.25.25.58.38.93.38.8 0 1.46-.65 1.46-1.46V7.46c0-.8-.65-1.46-1.46-1.46ZM15 16.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-9c0-.28.22-.5.5-.5h11c.28 0 .5.22.5.5v9Zm2.83-4.5L21 8.83v6.34L17.83 12Z"/></svg>');
    }
}

::-webkit-scrollbar {
    display: none;
}
</style>
