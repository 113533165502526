<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
    <div v-if="isShown" class="w-screen h-screen bottom-0 top-0 left-0 right-0 fixed z-20 inset-0 flex justify-center items-center bg-black bg-opacity-25">
        <div class="fixed right-0 top-0 left-0 bottom-0 -z-10 w-screen h-screen"></div>

        <div class="confirm-delete w-1/2 h-fit max-h-5/6 rounded-md bg-white flex flex-col justify-start items-center overflow-hidden">
            <h3>Are you sure you want to delete this Question ?</h3>

            <div class="btn-container">
                <button @click="deleteQuestion()">Yes</button>
                <button @click="this.closeConfirm()" class="btn-no">No</button>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "../store/index";
import axios from "axios";
//import LoaderComponent from './LoaderComponent';
import ToastNotification from "./ToastNotification.vue";

export default {
    name: "ConfirmDeleteQuestion",
    components: {
        // LoaderComponent,
        ToastNotification,
    },
    props: {
        isShown: Boolean,
        id: String,
        closeConfirm: Function,
    },
    data() {
        return {
            isLoading: false,

            isVisible: false,
            message: "",
            bgColor: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        deleteQuestion() {
            axios
                .delete(`https://server.go-platform.com/CustomQuestion/delete/${this.id}`, { withCredentials: true })
                .then(() => {
                    this.message = "Question deleted successfully!";
                    this.bgc = "success";
                    this.isVisible = true;
                    this.Store.allCustomQuestions = [];
                    this.Store.fetchCustomCompany();
                    this.Store.fetchCustomGoPlatform();
                    this.closeConfirm();
                })
                .catch((error) => {
                    console.error("Error submitting blog post", error);
                    this.message = "An error occurred while deleting the blog.";
                    this.bgc = "error";
                    this.isVisible = true;
                });
        },
    },
};
</script>
<style scoped>
body {
    overflow-y: hidden;
}

h3 {
    color: #343637;
    font-family: "Roboto";
    font-style: normal;
    font-size: 1.25rem;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3rem;
}

button {
    width: 15%;
    height: 40px;
    padding: 0.5rem;
    border-radius: 20px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

.btn-no {
    background: red;
}

.confirm-delete {
    padding: 1rem;
    border-radius: 10px;

    justify-content: space-around;
    align-items: center;
    background: #f4f7fe;
    border: 2px solid #edeff2;
    width: 40%;
    height: 30%;
}
</style>
