<template>
    <!-- <div v-if="showMenu" @click="toggleMenu" class="w-[100%] h-[100%] fixed top-0 left-0 right-0 bottom-0" style="z-index: 10; background: red"></div> -->
    <!-- <div class="" style="z-index: 30; background-color: red"> -->
    <div class="menu" v-if="showMenu">
        <div @click="archiveCandidate">
            <p>
                {{ candidateStatus === "active" ? $t("Archive") : $t("Activate") }}
            </p>
            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.4375 18.5714C1.4375 19.3616 2.07988 20 2.875 20H20.125C20.9201 20 21.5625 19.3616 21.5625 18.5714V5.71429H1.4375V18.5714ZM8.625 9.10714C8.625 8.8125 8.86758 8.57143 9.16406 8.57143H13.8359C14.1324 8.57143 14.375 8.8125 14.375 9.10714V9.46429C14.375 9.75893 14.1324 10 13.8359 10H9.16406C8.86758 10 8.625 9.75893 8.625 9.46429V9.10714ZM21.5625 0H1.4375C0.642383 0 0 0.638393 0 1.42857V3.57143C0 3.96429 0.323437 4.28571 0.71875 4.28571H22.2812C22.6766 4.28571 23 3.96429 23 3.57143V1.42857C23 0.638393 22.3576 0 21.5625 0Z"
                />
            </svg>
        </div>
        <!-- <div @click="toggleDelete">
            <p>{{ $t("Delete") }}</p>
        </div> -->
        <!-- </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
import { useStore } from "../store/index.js";
import axios from "axios";
export default {
    name: "CandidateMenu",
    props: {
        showMenu: Boolean,
        toggleMenu: Function,
        candidateStatus: String,
        candidateId: String,
        toggleLoading: Function,
        getCandidates: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        archiveCandidate() {
            this.toggleMenu();
            if (this.candidateStatus === "archived") {
                this.toggleLoading();
            }
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/candidates/switchStatus/${this.candidateId}`,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then(async () => {
                    if (this.candidateStatus === "active") {
                        await this.getCandidates();
                    } else {
                        await this.Store.getArchivedCandidates().then(() => {
                            this.toggleLoading();
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    position: absolute;
    // left: 101%;
    top: 22%;
    right: 12%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 123px;
    height: fit-content;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border: 1px solid #ccd7ec;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    z-index: 30;

    div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #2b3679;
        padding: 8px;
        transition: all 0.1s ease-in-out;

        svg {
            fill: #2b3679;
        }

        &:hover {
            cursor: pointer;
            color: white;
            background-color: #2196f3;

            p {
                color: #fff;
            }

            svg {
                fill: #fff;
            }
        }

        p {
            color: #0f172a;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    > :last-child {
        // margin-bottom: 30px;
        p {
            color: red;
        }
    }
}
</style>
