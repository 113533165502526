<template>
    <div>
        <div class="score-head">
            <div class="flex flex-row content-start items-center w-[70%]">
                <div class="svgBG">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                        <g clip-path="url(#clip0_5242_23445)">
                            <path
                                d="M11.2109 22C8.29356 22 5.49566 20.8411 3.43276 18.7782C1.36986 16.7153 0.210938 13.9174 0.210938 11C0.210938 8.08262 1.36986 5.28473 3.43276 3.22183C5.49566 1.15893 8.29356 0 11.2109 0C14.1283 0 16.9262 1.15893 18.9891 3.22183C21.052 5.28473 22.2109 8.08262 22.2109 11C22.2109 13.9174 21.052 16.7153 18.9891 18.7782C16.9262 20.8411 14.1283 22 11.2109 22ZM11.2109 19.8C13.5448 19.8 15.7832 18.8729 17.4335 17.2225C19.0838 15.5722 20.0109 13.3339 20.0109 11C20.0109 8.66609 19.0838 6.42778 17.4335 4.77746C15.7832 3.12714 13.5448 2.2 11.2109 2.2C8.87703 2.2 6.63872 3.12714 4.9884 4.77746C3.33808 6.42778 2.41094 8.66609 2.41094 11C2.41094 13.3339 3.33808 15.5722 4.9884 17.2225C6.63872 18.8729 8.87703 19.8 11.2109 19.8ZM10.1109 11.451V4.4H12.3109V10.549L16.6559 14.894L15.1049 16.445L10.1109 11.451Z"
                                fill="#2196f3"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_5242_23445">
                                <rect width="22" height="22" fill="white" transform="translate(0.210938)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <h3 class="w-[90%]">{{ $t("Distribution Time") }}</h3>
            </div>
            <span v-if="show" class="on-track flex flex-row content-start items-center">
                <img loading="lazy" decoding="async" src="@/assets/Images/icons/check-green.svg" alt="on-track" />
                {{ $t("On Track") }}
            </span>

            <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/bar-chart-icon.svg" alt="" /> -->
        </div>
        <div v-if="!show" class="noChart">
            <h1>Talents need to <span> Attempt Assessment</span> to show this content!</h1>
        </div>
        <div class="chartWrapper" v-if="show">
            <div>
                <p>{{ $t("This chart represents the time of candidates to pass the test after it was sent to them") }}.</p>

                <div class="description-container">
                    <div v-for="(item, index) in diskData" :key="index" class="description">
                        <div :style="{ backgroundColor: getColor(index) }" class="color-box"></div>
                        <div class="data-label">{{ item.label }}</div>
                    </div>
                </div>
            </div>
            <div class="donutWrapper">
                <DonutChart :diskData="diskData" :project="project" :label="false" />
            </div>
        </div>
    </div>
</template>

<script>
import DonutChart from "@/charts/DonutChart.vue";
import { useStore } from "@/store";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Destribution",
    props: ["project", "show"],
    data() {
        return {
            // diskData: [
            //     { label: "Data A", value: 30 },
            //     { label: "Data B", value: 30 },
            //     { label: "Data C", value: 40 },
            // ],
        };
    },
    computed: {
        diskData() {
            return this.Store.createDistributionData(this.project);
        },
    },
    components: { DonutChart },
    methods: {
        getColor(index) {
            const colors = ["#2196f3", "#05CD99", "#EABC49"];
            return colors[index % colors.length];
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
};
</script>

<style scoped lang="scss">
.score-head {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-top: 2%;

    h3 {
        color: #1b2559;
        font-family: DM Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 160% */
        letter-spacing: -0.4px;
        margin: 0;
    }
}
.score-head > div {
    // display: grid;
    // // grid-template-columns: 1fr 1fr;
    // justify-items: baseline;
    // align-items: center;
}
.score-head > div > span {
    color: #05cd99;
}
.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}
.score-head > select:focus {
    border: none;
    outline: none;
}
.score-head > img {
    justify-self: end;
    width: 50px;
    height: 50px;
}

.chartWrapper {
    display: grid;
    grid-template-columns: 65% 33%;
    gap: 1%;

    > :first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        p {
            width: 70%;
            text-align: left;
            color: #5c5f62;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 15px;
        }

        h3 {
            color: #1b2559;
            text-align: center;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 100% */
            letter-spacing: -0.48px;

            span {
                color: #05cd99;
            }
        }
    }
}

.color-box {
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.description-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.donutWrapper {
    width: 60%;
}
.on-track {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    margin: 2% 0;
    color: #05cd99;
    gap: 8px;
    display: flex;
    justify-content: center;
}
.svgBG {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.noChart {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    transform: translateY(-50%);
    h1 {
        width: 60%;
        color: #0f172a;
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px; /* 175% */
        letter-spacing: -0.48px;
        span {
            font-weight: 700;
        }
    }
}
</style>
