<template>
    <div class="tooltip flex" v-if="showGroupTooltip" :style="{ top: `${tooltipY}px`, left: `${tooltipX}px` }">
        <div v-for="(data, index) in groupData" :key="index" class="border-r-2 p-2">
            <div class="flex items-center my-[auto]">
                <div class="w-[40px] h-[40px] rounded-full overflow-hidden" v-if="data.img">
                    <img loading="lazy" decoding="async" :src="getImgUrl(data.img)" class="w-[40px] h-[fit-content] rounded-full" alt="" />
                </div>
                <div class="w-[40px] h-[40px] rounded-full overflow-hidden border-[1px] flex items-center justify-center" v-else>
                    <font-awesome-icon :icon="['far', 'user']" />
                </div>
                <h3>{{ data.name }}</h3>
            </div>
            <div class="w-full">
                <p><span> Score:</span> {{ data.score }}%</p>
                <p><span>Time:</span> {{ getTime(data.time) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GroupTooltip",
    props: {
        groupData: Array, // Data for the hovered circle
        showGroupTooltip: Boolean, // Flag to show/hide the tooltip
        tooltipX: Number, // X position of the tooltip
        tooltipY: Number, // Y position of the tooltip
    },
    methods: {
        getTime(time) {
            // Convert the number to a string and then split by dot
            const timeString = time?.toString();
            const timearr = timeString?.split(/\./);

            // Assuming timearr[0] contains minutes and timearr[1] contains seconds
            if (timearr) {
                return timearr[0] + "min" + timearr[1] + "sec";
            }
        },
        getImgUrl(img) {
            if (img) {
                var image = `data:image/png;base64,${img}`;

                return image;
            }
            return require("@/assets/Images/user.png");
        },
    },
};
</script>

<style scoped lang="scss">
.tooltip {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 20px;
    z-index: 999; /* Ensure the tooltip is on top of other elements */
    h3 {
        // padding: 0 2rem;
        color: var(#0f172a);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px; /* 116.667% */
        letter-spacing: -0.72px;
        margin-right: 0;
        margin-left: 5px;
        width: max-content;
    }
    p {
        padding: 0 1rem;
    }
    span {
        padding-right: 0.4rem;
        color: var(#0f172a);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px; /* 116.667% */
        letter-spacing: -0.72px;
    }
}
</style>
